<style lang="scss" scoped>
.my-collapse {
  border: 1px solid #ebeef5;
  margin-bottom: 40px;
  padding-left: 20px;
}
.my-collapse-title {
  i {
    font-size: 16px;
    margin-right: 10px;
    color: #999;
  }
  .el-collapse-item {
    border-left: solid 1px #ebeef5;
    border-right: solid 1px #ebeef5;
    padding: 0 20px;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
}
</style>
<template>
  <el-collapse class="my-collapse sizing">
    <el-collapse-item name="1">
      <template #title>
        <div class="my-collapse-title">
          <i class="el-icon-info"></i>條件篩選
        </div>
      </template>
      <el-form
        size="small"
        :inline="true"
        :ref="(el) => (state.formInline = el)"
        label-width="100px"
        :model="state.form"
        class="demo-form-inline"
      >
        <el-form-item label="編號" prop="multi">
          <el-input
            placeholder="訂位編號"
            v-model="state.form.seatch_name"
          ></el-input>
        </el-form-item>
        <el-form-item class="screen-btnaction">
          <el-button type="primary" @click="seach">查詢</el-button>
          <el-button @click="resetValue">重置</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";

export default {
  setup(props, { emit }) {
    const state = reactive({
      formInline: "",
      form: {
        seatch_name: "",
        region: "",
        sort: "",
        time: "",
      },
      options: [],
    });

    const resetValue = () => {
      // 重置
      state.form = {
        seatch_name: "",
        region: "",
        sort: "",
        time: "",
      };
    };

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http } = proxy;

    const focus = async () => {
      // 获取分类参数
      const { data } = await $http.getAllGoodsSort();
      state.options = [];
      Object.values(data).forEach((item) => {
        const obj = {
          value: item.id,
          label: item.sort_name,
        };
        if (Object.values(item.children).length) {
          obj.children = [];
          Object.values(item.children).forEach((curr) => {
            obj.children.push({
              value: curr.id,
              label: curr.sort_name,
            });
          });
        }
        state.options.push(obj);
      });
    };
    focus();

    const seach = () => {
      emit("seach", state.form);
    };

    return {
      state,
      resetValue,
      seach,
    };
  },
};
</script>
