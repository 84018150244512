<style lang="scss" scoped>
$height: 100px;
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $height;
  height: $height;
  line-height: $height;
  text-align: center;
}
.avatar {
  width: $height;
  height: $height;
  display: block;
}
</style>
<template>
  <el-dialog title="編輯商品" v-model="state.dialogFormVisible" width="1180px">
    <el-tabs type="border-card" v-model="state.tabs_index">
      <el-tab-pane label="基本信息" name="0">
        <pro-info ref="ProInfoRef" :popData="props.popData" :is_spec_show="false"></pro-info>
      </el-tab-pane>
      <el-tab-pane label="商品規格" name="1">
        <pro-spec :specData="state.specData" ref="SpecRef"></pro-spec
      ></el-tab-pane>
      <el-tab-pane label="商品圖片" name="2"
        ><pro-img ref="ImgRef" :fileList="state.fileList"></pro-img
      ></el-tab-pane>
      <el-tab-pane label="商品内容" name="3"
        ><pro-content
          ref="ContentRef"
          :text="props.popData.goods_introduce"
        ></pro-content
      ></el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, getCurrentInstance, ref, defineComponent, watch } from "vue";
import ProInfo from "@/views/GoodsManagement/components/pro-info";
import ProImg from "@/views/GoodsManagement/components/pro-img";
import ProContent from "@/views/GoodsManagement/components/pro-content";
import ProSpec from "@/views/GoodsManagement/components/pro-spec";

export default {
  components: {
    ProInfo,
    ProImg,
    ProContent,
    ProSpec,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
    },
    popData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $loading, $http } = proxy;
    let loading = null;

    // 表单验证
    let obj = {};
    watch(
      () => props.popData,
      (val) => {
        obj = JSON.parse(JSON.stringify(val));
        obj.specs = obj.specs.map((item) => {
          item.goods_attr_combine.forEach((curr) => {
            curr.checked = Boolean(curr.is_select);
            curr.pic = parseInt(curr.attr_values_price);
          });
          return item;
        });
        state.specData = [{ goods_attr_keys: obj.specs }];
        state.fileList = val.album.map((item, index) => {
          item.name = "img" + (index + 1) + ".png";
          item.url = item.img_url;
          return item;
        });
      },
      { deep: true }
    );
    const ProInfoRef = ref();
    const SpecRef = ref();
    const ImgRef = ref();
    const ContentRef = ref();

    // state
    const state = reactive({
      dialogFormVisible: props.dialogVisible,
      specData: [],
      specData_res: [],
      fileList: [],
      tabs_index: "0",
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) {
          state.dialogFormVisible = val;
          state.tabs_index = "0";
        }
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    // methods

    const add_submit = async () => {
      let result = {};
      if (state.tabs_index == 0) {
        const data = ProInfoRef.value.valueRef.ruleForm;
        result = {
          id: ProInfoRef.value.props.popData.id,
          goods_name: data.goods_name,
          goods_img: data.goods_img,
          describe: data.describe,
          is_hot: data.is_hot,
          feedback_integral: data.feedback_integral,
          keysword: data.keysword,
          has_specs: data.is_point,
          sort_id: data.classify[data.classify.length - 1],
          // goods_sort_id: ProInfoRef.value.props.popData.sort_id,
          goods_introduce: data.goods_introduce,
          price: data.price,
          cost: data.cost,
          supplier: data.datum,
          goods_stock: data.inventory,
          status: data.status,
        };
        const res = await $http.editGoodsInfo(result);
        if (res.status !== 200) return;
      } else if (state.tabs_index == 1) {
        const data = SpecRef.value.state.specData;
        const select_spec = [];
        data.forEach((item) => {
          const arr = item.goods_attr_combine.filter((curr) => curr.checked);
          const index = select_spec.findIndex((curr) => curr.id == item.id);
          if (arr.length) {
            const obj = {
              id: item.id,
              arr,
            };
            index !== -1
              ? (select_spec[index].arr = arr)
              : select_spec.push(obj);
          }
        });
        const select_spec_checked = [];
        select_spec.forEach((item) => {
          item.arr.forEach((curr) => {
            const obj = {
              attr_values_id: curr.attr_values_id,
              id: curr.id,
              attr_values_price: curr.pic,
              attr_key_id: item.id,
            };
            select_spec_checked.push(obj);
          });
        });
        result = {
          goods_id: ProInfoRef.value.props.popData.id,
          goods_specs: select_spec_checked,
          sort_id: ProInfoRef.value.props.popData.sort_id,
        };
        const res = await $http.editGoodsSkus(result);
        if (res.status !== 200) return;
      } else if (state.tabs_index == 2) {
        result = {
          goods_id: ProInfoRef.value.props.popData.id,
          imgs: ImgRef.value.state.imgList,
        };
        const res = await $http.editGoodsPicture(result);
        if (res.status !== 200) return;
      } else if (state.tabs_index == 3) {
        result = {
          goods_introduce: ContentRef.value.getText(),
          id: ProInfoRef.value.props.popData.id,
          goods_stock: ProInfoRef.value.valueRef.ruleForm.inventory,
        };
        const res = await $http.editGoodsInfo(result);
        if (res.status !== 200) return;
      }
      $message.success("修改成功");
      state.dialogFormVisible = false;
      emit("reloadData", true);
    };

    return {
      state,
      add_submit,
      props,
      ProInfoRef,
      SpecRef,
      ImgRef,
      ContentRef,
    };
  },
};
</script>
